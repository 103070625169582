import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mTitle1,
  neutralDay,
  wBody2,
  wTitle1,
} from "@teamsparta/design-system";
import { DisplayResolution } from "src/styles/themes/device";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 120px 16px;

  ${DisplayResolution.TabletAndDesktop} {
    padding: 140px 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  ${DisplayResolution.TabletAndDesktop} {
    gap: 50px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  ${DisplayResolution.TabletAndDesktop} {
    gap: 24px;
  }
`;

export const MobileOnly = styled.div`
  display: block;
  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const DesktopAndTabletOnly = styled.div`
  display: none;

  ${DisplayResolution.TabletAndDesktop} {
    display: block;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${DisplayResolution.TabletAndDesktop} {
    gap: 10px;
  }
`;

export const Title = styled.span`
  ${mTitle1}
  color: ${neutralDay.gray100} !important;

  ${DisplayResolution.TabletAndDesktop} {
    ${wTitle1}
  }
`;

export const Reason = styled.span`
  ${mBody2}
  color: ${neutralDay.gray70} !important;
  white-space: pre-line;
  text-align: center;

  ${DisplayResolution.TabletAndDesktop} {
    ${wBody2}
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #141617;
  cursor: pointer;

  min-width: 70px;
  padding: 13px 14px;
  border-radius: 8px;
`;

export const ButtonTextWrapper = styled.span`
  ${mBody1}
  color: ${neutralDay.gray0} !important;
  padding: 0 4px;
`;
