import { useRouter } from "next/router";
import { useEffect } from "react";
import { ErrorMessage } from "src/components/templates/404/ErrorMessage";

const PageNotFound = () => {
  const router = useRouter();
  useEffect(() => {
    if (router.isReady && window.location.href.includes("/blog")) {
      alert("죄송합니다. 해당 글은 삭제 되었습니다!");
      router.push("/blog");
    }
  }, [router.isReady]);

  return (
    <ErrorMessage
      title="페이지를 찾을 수 없습니다."
      reason={`페이지가 사라졌거나 다른 링크로 변경되었습니다.\n도움을 요청해 주시면 올바른 링크를 전달드릴게요.`}
      button={{
        text: "도움 요청하기",
        onClick: () => {
          window.open(
            "https://spartacodingclub.channel.io/support-bots/41731",
            "_blank"
          );
        },
      }}
    />
  );
};

export default PageNotFound;
