import * as S from "./errorMessage.style";
import { Warning } from "./Warning.svg";

type ErrorMessageProps = {
  title: string;
  reason?: string;
  button?: {
    text: string;
    onClick: () => void;
  };
};
export const ErrorMessage = ({ title, reason, button }: ErrorMessageProps) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.InnerContainer>
          <S.MobileOnly>
            <Warning isMobile={true} />
          </S.MobileOnly>
          <S.DesktopAndTabletOnly>
            <Warning isMobile={false} />
          </S.DesktopAndTabletOnly>
          
          <S.TitleContainer>
            <S.Title>{title}</S.Title>
            {reason && <S.Reason>{reason}</S.Reason>}
          </S.TitleContainer>
        </S.InnerContainer>

        {button && (
          <S.Button onClick={button.onClick}>
            <S.ButtonTextWrapper>{button.text}</S.ButtonTextWrapper>
          </S.Button>
        )}
      </S.Container>
    </S.Wrapper>
  );
};
